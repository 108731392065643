import React from "react"
import { Map, Marker, ZoomControl} from "pigeon-maps"

function MyMap() {
  return (
    <Map height={500} defaultCenter={[46.606452,19.6812403]} defaultZoom={15}>
      <Marker width={50} anchor={[46.606452,19.6812403]} />
      <ZoomControl />
    </Map>
  )
}

export default MyMap;
