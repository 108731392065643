import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Technológiák',
    paragraph: 'A horganyzás ára (Ft/kg vagy Ft/dm²) és a technológia hatékonysága jelentősen függ a kezelendő '+
    'termék kialakításának sajátosságaitól (alak, méret, furatok elhelyezkedése, stb.).'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                {/*<div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                </div>*/}
                <h3 className="mt-0 mb-12">
                  Horganyzás
                </h3>
                <p className="m-0">
                A horganyzás egy olyan fémbevonási eljárás, amely során a vas vagy acél tárgyakat vékony rétegben horganyzócinkkel fedik be. Ez védi a tárgyakat a korróziótól és rozsdától, és hosszabb élettartamot biztosít számukra. A horganyzás általában forró vagy hideg eljárással végezhető, attól függően, hogy milyen vastag rétegben kell felvinni a cinket. A forró horganyzás során a tárgyakat először megmossák, majd korróziógátló anyagba mártják, majd forró cinkbe merítik. A hideg horganyzás során a tárgyakat először tisztítják, majd elektrokémiai eljárással egy vékonyabb rétegben fedik be cinkkel. A horganyzás számos iparágban használatos, például a járműgyártásban, az építőiparban és az energetikai iparban.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/zinc-plating-on-fasteners.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                {/*<div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                </div>*/}
                <h3 className="mt-0 mb-12">
                  Ónozás
                </h3>
                <p className="m-0">
                Az ónozás egy fémbevonási eljárás, amely során a tárgyat ónnal vonják be vékony rétegben. Az ónozás célja általában a fém felületének korróziógátlóvá tétele, vagy dekoratív célból történik. Az ónozás során először megtisztítják a fém felületét, majd ónnal fedik be. Az ón védelmet nyújt a korrózió és az oxidáció ellen, és a bevonatot könnyű kezelni. Az ónozást széles körben alkalmazzák a csaptelepek, elektromos alkatrészek, élelmiszeripari berendezések, ékszerek és más díszítő tárgyak előállításában. Az ónozás előnye, hogy egy alacsony olvadáspontú fém, így alacsony hőmérsékleten is felvihető a fémfelületre, ami előnyös az érzékenyebb anyagoknál.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/tin-plating.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                {/*<div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                </div>*/}
                <h3 className="mt-0 mb-12">
                  Passziválás
                </h3>
                <p className="m-0">
                A passziválás egy fémfelületkezelési eljárás, amely során a fém felületét oxidált állapotba hozzák, ami védelmet nyújt a korrózió és a rozsdásodás ellen. A passziválás általában a rozsdamentes acél és alumínium kezelésére alkalmazható. Az eljárás során a fém felületét savval vagy egyéb vegyi anyaggal kezelik, majd egy védőréteget képeznek a felületen, amely megakadályozza az oxidáció és korrózió kialakulását. A passziválás előnye, hogy védi a fémfelületet az időjárás, a nedvesség és más környezeti hatások okozta károsodásoktól, és hosszabb élettartamot biztosít a fémfelületnek. A passziválás fontos szerepet játszik az élelmiszeriparban és az orvosi berendezések gyártásában, ahol a higiénia és az egészségügyi előírások miatt a felületnek különleges védelmet kell biztosítania.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/passivization.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;